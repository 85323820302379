<template>
  <main class="kb-main" id="kb-educourse-status">
    <lxp-main-header :show-breadcrumb="true" :show-title="true" title="의무교육 현황"/>
    <!-- main-content -->
<!--    <div v-for="(item, index) in items" :class="index > 0 ? 'mt-5' : ''" :key="index">-->
<!--      <MobileTrainRegularCourse-->
<!--          :title="item.title"-->
<!--          :items="item.items"-->
<!--      />-->
<!--    </div>-->
    <template v-if="isReady">
      <div v-for="(item, index) in renderItems" :class="index > 0 ? 'mt-5' : ''" :key="index">
        <MobileDutyRegularCourse
            :title="item.title"
            :items="item.items"
        />
      </div>
    </template>
    <br><br><br><br><br>
  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import MobileDutyRegularCourse from '@/components/duty/mobile/MobileDutyRegularCourse';
import {dutyRegularSetup} from '@/assets/js/modules/duty/duty-regular-setup';

export default {
  name: 'MobileDutyLrnStatus',
  components: {LxpMainHeader, MobileDutyRegularCourse},
  setup: dutyRegularSetup
}
</script>